<template>
  <div></div>
</template>
<script>
import { http } from '@/services'

export default {
  mounted() {
    if (this.$store.getters.partner) {
      this.$router
        .push({
          name: 'DistributionPartner'
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      if (this.$store.getters.partner_ex && this.$store.getters.partner_ex.distribution_sole_primary) {
        // turn off ex partner sole
        let partnerClone = Object.assign({}, this.$store.getters.partner_ex)
        http.patch('/wills/api/person', partnerClone).then((response) => {
          this.$store.commit('personAdd', response.data)
        })
      }
      this.$router
        .push({
          name: 'DistributionSplit'
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>
